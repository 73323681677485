// Import for Datapicker

import { registerLocale } from "react-datepicker";
import * as localefns from "date-fns/locale";

import { DEFAULT_LANGUAGE, USER_PROFILE_TYPES } from "../constants";
import { TenantStorage } from "./tenant-storage";
import moment from "moment";
import "moment/min/locales";

import store from "../redux/store";
import { PhaseTypeName } from "../types";
import { getFeatureConfiguration } from "./feature-configuration-helper";
import { tenantIsUSA } from "./tenant-helper";
import { isSuperAdmin } from "./role-helper";

// Custom Locale object
export interface LocaleObject {
  locale: string;
  messages: any;
}

// Default languages supported by the app - check isoCode exits in date-fns/locale
type AppLanguage =
  | "en-US"
  | "en-USA"
  | "es-ES"
  | "ro-RO"
  | "tr-TR"
  | "cs-CZ"
  | "fr-FR"
  | "de-DE"
  | "nl-NL"
  | "it-IT"
  | "pl-PL"
  | "zh-CN"
  | "ja-JP"
  | "pt-PT"
  | "fr-LU"
  | "fr-CH"
  | "fr-BE"
  | "fr-CA"
  | "de-CH"
  | "en-AU"
  | "en-CA"
  | "en-UK"
  | "en-JP"
  | "en-IN"
  | "es-MX"
  | "es-CO"
  | "es-EC"
  | "es-AR"
  | "es-UY"
  | "es-LT"
  | "es-US"
  | "nl-BE"
  | "it-CH"
  | "ca-ES";

type Region = "global" | "regional";

export interface LanguageCodesObject {
  isoCode: AppLanguage;
  name: string | any;
  global?: any;
  siblings?: any;
  others?: { image?: string; className?: string; isInternalLanguage?: boolean };
}
export const importLanguagesObject: LanguageCodesObject[] = [
  //global / parent
  { isoCode: "en-US", name: "English (US)", global: true },
  { isoCode: "es-ES", name: "Español (ES)", global: true },
  { isoCode: "ro-RO", name: "Română", global: true },
  { isoCode: "tr-TR", name: "Türkçe", global: true },
  { isoCode: "cs-CZ", name: "čeština", global: true },
  { isoCode: "fr-FR", name: "Français (FR)", global: true },
  { isoCode: "de-DE", name: "Deutsch (DE)", global: true },
  { isoCode: "nl-NL", name: "Nederlands", global: true },
  { isoCode: "it-IT", name: "Italiano (IT)", global: true },
  { isoCode: "pl-PL", name: "Polski", global: true },
  { isoCode: "zh-CN", name: "汉语", global: true },
  { isoCode: "ja-JP", name: "日本語", global: true },
  { isoCode: "pt-PT", name: "Português (PT)", global: true },
  { isoCode: "ca-ES", name: "Catalan (ES)", global: true }, //inherits from es-ES

  //regional
  { isoCode: "en-USA", name: "English (USA)", others: { isInternalLanguage: true } }, //show only to superAdmin
  { isoCode: "en-AU", name: "English (AU)" },
  { isoCode: "en-CA", name: "English (CA)" },
  { isoCode: "en-UK", name: "English (UK)" },
  { isoCode: "en-IN", name: "English (IN)" },
  { isoCode: "en-JP", name: "English (JP)" },
  { isoCode: "es-LT", name: "Español (LATAM)" },
  { isoCode: "es-EC", name: "Español (EC)" },
  { isoCode: "es-CO", name: "Español (CO)" },
  { isoCode: "es-MX", name: "Español (MX)" },
  { isoCode: "es-AR", name: "Español (AR)" },
  { isoCode: "es-UY", name: "Español (UY)" },
  { isoCode: "es-US", name: "Español (US)" },
  { isoCode: "fr-CH", name: "Français (CH)" },
  { isoCode: "fr-CA", name: "Français (CA)" },
  { isoCode: "fr-LU", name: "Français (LU)" },
  { isoCode: "fr-BE", name: "Français (BE)" },
  { isoCode: "nl-BE", name: "Vlaams (BE)" },
  { isoCode: "de-CH", name: "Deutsch (CH)" },
  { isoCode: "it-CH", name: "Italiano (CH)" },
];

// Not used but just left here in case it is needed in the future not to create it again
export const languagesISO639 = [
  { isoCode: "ab", name: "Abkhazian" },
  { isoCode: "aa", name: "Afar" },
  { isoCode: "af", name: "Afrikaans" },
  { isoCode: "ak", name: "Akan" },
  { isoCode: "sq", name: "Albanian" },
  { isoCode: "am", name: "Amharic" },
  { isoCode: "ar", name: "Arabic" },
  { isoCode: "an", name: "Aragonese" },
  { isoCode: "hy", name: "Armenian" },
  { isoCode: "as", name: "Assamese" },
  { isoCode: "av", name: "Avaric" },
  { isoCode: "ae", name: "Avestan" },
  { isoCode: "ay", name: "Aymara" },
  { isoCode: "az", name: "Azerbaijani" },
  { isoCode: "bm", name: "Bambara" },
  { isoCode: "ba", name: "Bashkir" },
  { isoCode: "eu", name: "Basque" },
  { isoCode: "be", name: "Belarusian" },
  { isoCode: "bn", name: "Bengali" },
  { isoCode: "bi", name: "Bislama" },
  { isoCode: "bs", name: "Bosnian" },
  { isoCode: "br", name: "Breton" },
  { isoCode: "bg", name: "Bulgarian" },
  { isoCode: "my", name: "Burmese" },
  { isoCode: "ca", name: "Catalan, Valencian" },
  { isoCode: "ch", name: "Chamorro" },
  { isoCode: "ce", name: "Chechen" },
  { isoCode: "ny", name: "Chichewa, Chewa, Nyanja" },
  { isoCode: "zh", name: "Chinese" },
  { isoCode: "cu", name: "Church Slavonic, Old Slavonic, Old Church Slavonic" },
  { isoCode: "cv", name: "Chuvash" },
  { isoCode: "kw", name: "Cornish" },
  { isoCode: "co", name: "Corsican" },
  { isoCode: "cr", name: "Cree" },
  { isoCode: "hr", name: "Croatian" },
  { isoCode: "cs", name: "Czech" },
  { isoCode: "da", name: "Danish" },
  { isoCode: "dv", name: "Divehi, Dhivehi, Maldivian" },
  { isoCode: "nl", name: "Dutch, Flemish" },
  { isoCode: "dz", name: "Dzongkha" },
  { isoCode: "en", name: "English" },
  { isoCode: "eo", name: "Esperanto" },
  { isoCode: "et", name: "Estonian" },
  { isoCode: "ee", name: "Ewe" },
  { isoCode: "fo", name: "Faroese" },
  { isoCode: "fj", name: "Fijian" },
  { isoCode: "fi", name: "Finnish" },
  { isoCode: "fr", name: "French" },
  { isoCode: "fy", name: "Western Frisian" },
  { isoCode: "ff", name: "Fulah" },
  { isoCode: "gd", name: "Gaelic, Scottish Gaelic" },
  { isoCode: "gl", name: "Galician" },
  { isoCode: "lg", name: "Ganda" },
  { isoCode: "ka", name: "Georgian" },
  { isoCode: "de", name: "German" },
  { isoCode: "el", name: "Greek, Modern (1453–)" },
  { isoCode: "kl", name: "Kalaallisut, Greenlandic" },
  { isoCode: "gn", name: "Guarani" },
  { isoCode: "gu", name: "Gujarati" },
  { isoCode: "ht", name: "Haitian, Haitian Creole" },
  { isoCode: "ha", name: "Hausa" },
  { isoCode: "he", name: "Hebrew" },
  { isoCode: "hz", name: "Herero" },
  { isoCode: "hi", name: "Hindi" },
  { isoCode: "ho", name: "Hiri Motu" },
  { isoCode: "hu", name: "Hungarian" },
  { isoCode: "is", name: "Icelandic" },
  { isoCode: "io", name: "Ido" },
  { isoCode: "ig", name: "Igbo" },
  { isoCode: "id", name: "Indonesian" },
  { isoCode: "ia", name: "Interlingua (International Auxiliary Language Association)" },
  { isoCode: "ie", name: "Interlingue, Occidental" },
  { isoCode: "iu", name: "Inuktitut" },
  { isoCode: "ik", name: "Inupiaq" },
  { isoCode: "ga", name: "Irish" },
  { isoCode: "it", name: "Italian" },
  { isoCode: "ja", name: "Japanese" },
  { isoCode: "jv", name: "Javanese" },
  { isoCode: "kn", name: "Kannada" },
  { isoCode: "kr", name: "Kanuri" },
  { isoCode: "ks", name: "Kashmiri" },
  { isoCode: "kk", name: "Kazakh" },
  { isoCode: "km", name: "Central Khmer" },
  { isoCode: "ki", name: "Kikuyu, Gikuyu" },
  { isoCode: "rw", name: "Kinyarwanda" },
  { isoCode: "ky", name: "Kirghiz, Kyrgyz" },
  { isoCode: "kv", name: "Komi" },
  { isoCode: "kg", name: "Kongo" },
  { isoCode: "ko", name: "Korean" },
  { isoCode: "kj", name: "Kuanyama, Kwanyama" },
  { isoCode: "ku", name: "Kurdish" },
  { isoCode: "lo", name: "Lao" },
  { isoCode: "la", name: "Latin" },
  { isoCode: "lv", name: "Latvian" },
  { isoCode: "li", name: "Limburgan, Limburger, Limburgish" },
  { isoCode: "ln", name: "Lingala" },
  { isoCode: "lt", name: "Lithuanian" },
  { isoCode: "lu", name: "Luba-Katanga" },
  { isoCode: "lb", name: "Luxembourgish, Letzeburgesch" },
  { isoCode: "mk", name: "Macedonian" },
  { isoCode: "mg", name: "Malagasy" },
  { isoCode: "ms", name: "Malay" },
  { isoCode: "ml", name: "Malayalam" },
  { isoCode: "mt", name: "Maltese" },
  { isoCode: "gv", name: "Manx" },
  { isoCode: "mi", name: "Maori" },
  { isoCode: "mr", name: "Marathi" },
  { isoCode: "mh", name: "Marshallese" },
  { isoCode: "mn", name: "Mongolian" },
  { isoCode: "na", name: "Nauru" },
  { isoCode: "nv", name: "Navajo, Navaho" },
  { isoCode: "nd", name: "North Ndebele" },
  { isoCode: "nr", name: "South Ndebele" },
  { isoCode: "ng", name: "Ndonga" },
  { isoCode: "ne", name: "Nepali" },
  { isoCode: "no", name: "Norwegian" },
  { isoCode: "nb", name: "Norwegian Bokmål" },
  { isoCode: "nn", name: "Norwegian Nynorsk" },
  { isoCode: "ii", name: "Sichuan Yi, Nuosu" },
  { isoCode: "oc", name: "Occitan" },
  { isoCode: "oj", name: "Ojibwa" },
  { isoCode: "or", name: "Oriya" },
  { isoCode: "om", name: "Oromo" },
  { isoCode: "os", name: "Ossetian, Ossetic" },
  { isoCode: "pi", name: "Pali" },
  { isoCode: "ps", name: "Pashto, Pushto" },
  { isoCode: "fa", name: "Persian" },
  { isoCode: "pl", name: "Polish" },
  { isoCode: "pt", name: "Portuguese" },
  { isoCode: "pa", name: "Punjabi, Panjabi" },
  { isoCode: "qu", name: "Quechua" },
  { isoCode: "ro", name: "Romanian, Moldavian, Moldovan" },
  { isoCode: "rm", name: "Romansh" },
  { isoCode: "rn", name: "Rundi" },
  { isoCode: "ru", name: "Russian" },
  { isoCode: "se", name: "Northern Sami" },
  { isoCode: "sm", name: "Samoan" },
  { isoCode: "sg", name: "Sango" },
  { isoCode: "sa", name: "Sanskrit" },
  { isoCode: "sc", name: "Sardinian" },
  { isoCode: "sr", name: "Serbian" },
  { isoCode: "sn", name: "Shona" },
  { isoCode: "sd", name: "Sindhi" },
  { isoCode: "si", name: "Sinhala, Sinhalese" },
  { isoCode: "sk", name: "Slovak" },
  { isoCode: "sl", name: "Slovenian" },
  { isoCode: "so", name: "Somali" },
  { isoCode: "st", name: "Southern Sotho" },
  { isoCode: "es", name: "Spanish, Castilian" },
  { isoCode: "su", name: "Sundanese" },
  { isoCode: "sw", name: "Swahili" },
  { isoCode: "ss", name: "Swati" },
  { isoCode: "sv", name: "Swedish" },
  { isoCode: "tl", name: "Tagalog" },
  { isoCode: "ty", name: "Tahitian" },
  { isoCode: "tg", name: "Tajik" },
  { isoCode: "ta", name: "Tamil" },
  { isoCode: "tt", name: "Tatar" },
  { isoCode: "te", name: "Telugu" },
  { isoCode: "th", name: "Thai" },
  { isoCode: "bo", name: "Tibetan" },
  { isoCode: "ti", name: "Tigrinya" },
  { isoCode: "to", name: "Tonga (Tonga Islands)" },
  { isoCode: "ts", name: "Tsonga" },
  { isoCode: "tn", name: "Tswana" },
  { isoCode: "tr", name: "Turkish" },
  { isoCode: "tk", name: "Turkmen" },
  { isoCode: "tw", name: "Twi" },
  { isoCode: "ug", name: "Uighur, Uyghur" },
  { isoCode: "uk", name: "Ukrainian" },
  { isoCode: "ur", name: "Urdu" },
  { isoCode: "uz", name: "Uzbek" },
  { isoCode: "ve", name: "Venda" },
  { isoCode: "vi", name: "Vietnamese" },
  { isoCode: "vo", name: "Volapük" },
  { isoCode: "wa", name: "Walloon" },
  { isoCode: "cy", name: "Welsh" },
  { isoCode: "wo", name: "Wolof" },
  { isoCode: "xh", name: "Xhosa" },
  { isoCode: "yi", name: "Yiddish" },
  { isoCode: "yo", name: "Yoruba" },
  { isoCode: "za", name: "Zhuang, Chuang" },
  { isoCode: "zu", name: "Zulu" },
];

export interface LiteralObj {
  id: string | undefined;
  forceLanguage?: string;
  strictLanguage?: boolean;
  returnId?: boolean;
}

export const removeHyphens = (language: string) => {
  return language.replace("-", "").replace("_", "").trim() || DEFAULT_LANGUAGE;
};
export const importLanguageFilesFromJSON = (_importLanguages: LanguageCodesObject[]) => {
  const tempObject: any = { isoCode: {}, name: {} };
  _importLanguages.forEach((language: LanguageCodesObject) => {
    const region: Region = language?.global === true ? "global" : "regional";
    tempObject.isoCode[language?.isoCode] = require(`../translations/${region}/${language.isoCode}.json`); //region and isoCode should be strongly typed for webpack import
    tempObject.name[language?.isoCode] = language?.name;
  });
  // add globalLanguages (they work as parent languages)
  const extendedData = {
    global: { en: "en-US", es: "es-ES", fr: "fr-FR", de: "de-DE", zh: "zh-CN", pt: "pt-PT", it: "it-IT", nl: "nl-NL", ca: "es-ES" },
    // languages will try to inherit from siblings before inheriting from global
    siblings: { esEC: "es-LT", esCO: "es-LT", esMX: "es-LT", esAR: "es-LT", esUY: "es-LT", esUS: "es-LT" },
  };
  return {
    ...tempObject,
    ...extendedData,
  };
};

// Import the different locale-message files
const languagesObject = importLanguageFilesFromJSON(importLanguagesObject);
export const languages: LanguageCodesObject = {
  isoCode: languagesObject.isoCode,
  name: languagesObject.name,
  global: languagesObject.global,
  siblings: languagesObject.siblings,
};
const frontEndDictionary = languages.isoCode as LocaleObject["messages"];

export const getUserProfileName = (role: string) => {
  const finalRole = role === USER_PROFILE_TYPES.user ? USER_PROFILE_TYPES.fullDeskRecruiter : capitalize(role?.replace("user", "").replace(/-/g, "\n").trim());
  return finalRole;
};

export const iso2ToLongLanguage = (language: string) => {
  // languages not in the global or the nearest iso2-code
  return DEFAULT_LOCALES.includes(language) ? language : DEFAULT_LOCALES.find((languageName: string) => language === languageName?.split("-")[0]) || DEFAULT_LANGUAGE;
};

const tenantStorage = new TenantStorage();
const internalLocalefns = { ...localefns } as any;
export const DEFAULT_LOCALES: string[] = importLanguagesObject.map((lang) => lang.isoCode);
// Browser locale
export const BROWSER_LANGUAGE = window.location.href.indexOf("sourcing/") < 0 ? navigator.language : tenantStorage.getLanguage();
// Current App locale used ONLY for API-calls
export let CURRENT_LOCALE = BROWSER_LANGUAGE?.split(/[-_]/)[0];
document.documentElement.setAttribute("lang", CURRENT_LOCALE);
const languageNames = DEFAULT_LOCALES.map((language: any) => language?.split(/[-_]/)[0]);
document.documentElement.setAttribute("translate", languageNames.includes(CURRENT_LOCALE) ? "no" : "yes");

// Map of currently supported languages
const localeMessages: any = {};
DEFAULT_LOCALES.forEach((locale: string) => {
  localeMessages[locale] = {
    locale: iso2ToLongLanguage(locale),
    messages: languages.isoCode[iso2ToLongLanguage(locale) as any],
  };
});

const originalToLocaleDateString = Date.prototype.toLocaleDateString;

// Define your custom locale mapping
const localeMapping: Record<string, string> = {
  "en-USA": "en-US",
  "en-UK": "en-GB",
};

// Override the toLocaleDateString
Date.prototype.toLocaleDateString = function (this: Date, locales?: string | string[], options?: Intl.DateTimeFormatOptions): string {
  // Handle the case where locales is a single string
  if (typeof locales === "string") {
    const standardLocale = localeMapping[locales] || locales;
    return originalToLocaleDateString.call(this, standardLocale, options);
  }
  // Handle the case where locales is an array of strings
  if (Array.isArray(locales)) {
    const mappedLocales = locales.map((locale) => localeMapping[locale] || locale);
    return originalToLocaleDateString.call(this, mappedLocales, options);
  }
  // Default behavior for undefined or other cases
  return originalToLocaleDateString.call(this, locales, options);
};

export function getLocaleDate(locale: string) {
  const iso2Locale = locale?.split("-")[0];
  let localeLong = removeHyphens(locale);
  if (localeLong === "enUK") localeLong = "enGB";
  if (localeLong === "enUSA") localeLong = "enUS";

  registerLocale(iso2Locale, internalLocalefns[localeLong] || internalLocalefns[iso2Locale] || localefns.enUS);
  return iso2Locale;
}

/**
 * Method that returns a {@link LocaleObject} to be used to
 * get both messages and locale to be set in the app of any
 * given locale string
 *
 * @param language is a {@link string} containing the locale to be parsed
 * @param defaultLocale is a {@link string} containing the default locale to be set if there is such language
 * @returns an {@link LocaleObject} object
 */
export function getLocale(language: string, defaultLocale?: string): LocaleObject {
  const defaultLanguage = language || "";
  const _language = defaultLocale ? defaultLocale : defaultLanguage;
  const splittedLanguage = _language?.split(/[-_]/);
  //return  enUS || en || default languages
  return localeMessages[_language] || localeMessages[iso2ToLongLanguage(splittedLanguage[0])] || localeMessages[DEFAULT_LANGUAGE];
}

/**
 * Method that sets the {@link CURRENT_LOCALE} variable for the API-calls
 * @param locale is a {@link string} with the locale to be set
 */
export function setCurrentLocale(locale: string) {
  CURRENT_LOCALE = locale;
  tenantStorage.setLanguage(locale);
}

/**
 * Method that returns a {@link string} with the text to be displayed
 * on the language dropdown
 *
 * @param lang a {@link string} containing the locale
 * @returns a {@link string} with the language translated
 */
export function getLanguageString(lang: string): string {
  // default:
  return languages?.name?.[lang] || "unknown";
}

/**
 * Function that returns an image corresponding to the given locale
 *
 * @param {string} localeString is a string with the locale
 */
export function getLocaleImage(localeString: string) {
  if (DEFAULT_LOCALES.includes(iso2ToLongLanguage(localeString))) {
    return require(`../assets/images/common/flags/flag-${iso2ToLongLanguage(localeString)}.png`)?.default;
  }
}
export function hideLanguage(language: string, { showToSuperAdmins = false, showToDevelopers = false }) {
  const showToDeveloper = (store.getState()?.tenant?.userProperties?.developerFlag && showToDevelopers) === true;
  const showToSuperAdmin = (isSuperAdmin() && showToSuperAdmins) === true;
  const languageObject = importLanguagesObject.find((lang: LanguageCodesObject) => lang?.isoCode === language);
  if (languageObject?.others?.isInternalLanguage === true && showToDeveloper === false && showToSuperAdmin === false) return true;

  return false;
}

/**
 * Method that returns a localized date for a given {@link number} timestamp
 *
 * @param timestamp a {@link number} with the timestamp
 */
export function getTableDate(timestamp: number) {
  if (timestamp === null) {
    return "-";
  }

  const dateObj = new Date(timestamp);
  const options: Intl.DateTimeFormatOptions = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  let dateString = dateObj.toLocaleDateString(CURRENT_LOCALE, options);
  dateString = capitalize(dateString)?.split(".").join("");
  return dateString;
}

/**
 * Method that returns a localized date for a given {@link number} timestamp
 *
 * @param timestamp a {@link Date} with the wanted Date
 */
export function getTimeLineDate(timestamp: any, useRecruiterTimeZone: boolean = false) {
  const recruiterProfileFeature = getFeatureConfiguration("General Settings", "recruiter-profile");
  if (timestamp === undefined) return "";
  moment.locale(CURRENT_LOCALE);
  const format = recruiterProfileFeature?.config?.timeFormat === "halfDay" ? "ddd, MMM DD, YYYY, hh:mm a" : "ddd, MMM DD, YYYY, HH:mm:ss";
  const dateString = moment(timestamp).tz(moment.tz.guess()).format(format) + ` (${moment.tz.guess()})`;
  return dateString;
}

/**
 * Method that returns a localized date for a given {@link number} timestamp for a custom timezone
 *
 * @param timestamp a {@link Date} with the wanted Date
 */
export function getTimeLineDateCustomTimezone(timestamp: any, timezone: string) {
  const recruiterProfileFeature = getFeatureConfiguration("General Settings", "recruiter-profile");
  if (timestamp === undefined) return "";
  moment.locale(CURRENT_LOCALE);
  const format = recruiterProfileFeature?.config?.timeFormat === "halfDay" ? "ddd, MMM DD, YYYY, hh:mma:ss a" : "ddd, MMM DD, YYYY, HH:mm:ss";
  const dateString = moment(timestamp).tz(timezone).format(format) + ` (${timezone})`;
  return dateString;
}

// Helper function for the getLocalDate method
export const capitalize = (str: string, lower: boolean = false) => {
  const words = (lower ? str?.toLowerCase() : str)?.split(" ");
  let newWords = "";
  words?.forEach((word) => (newWords = newWords + word[0]?.toUpperCase() + word?.slice(1) + " "));
  return newWords.trim();
};

export const toLiteral = (literalObj: LiteralObj) => {
  const id = literalObj.id || "";
  let literal = id;
  try {
    const dataBaseDictionary = store?.getState()?.literals?.literals;
    const { strictLanguage, forceLanguage } = literalObj;
    const returnId = literalObj?.returnId !== false;
    let language = forceLanguage || tenantStorage.getLanguage() || CURRENT_LOCALE;
    if (tenantIsUSA() && language === "en-US") language = "en-USA"; //special case for en-USA dictionary
    const parentLanguage = languages.global?.[language?.split("-")?.[0]]; //en is parent language of every en-??
    const siblingLanguage = languages.siblings?.[language];
    let _word = dataBaseDictionary?.[language]?.[id] || frontEndDictionary?.[language]?.[id] || "";
    if (strictLanguage) return _word; // Prevents inheritance. It will render nothing if the value doesn't exist in the global language
    if (!_word && siblingLanguage) _word = dataBaseDictionary?.[siblingLanguage]?.[id] || frontEndDictionary?.[siblingLanguage]?.[id] || "";
    if (!_word) _word = dataBaseDictionary?.[parentLanguage]?.[id] || frontEndDictionary?.[parentLanguage]?.[id] || "";
    if (!_word) _word = dataBaseDictionary?.[DEFAULT_LANGUAGE]?.[id] || frontEndDictionary?.[DEFAULT_LANGUAGE]?.[id] || "";
    literal = _word || (returnId ? id : ""); // If no value is found, just return the key unless returnId was false
  } catch (err) {
    console.error(err);
  }

  return literal;
};

export const dayNameLocale = (day: number) => {
  const localeDay = moment()
    ?.locale(CURRENT_LOCALE)
    ?.isoWeekday(day + 1)
    .format("ddd");
  return capitalize(localeDay?.replace(".", ""));
};

export function getTenantLanguagesOptions() {
  const _languages = store?.getState()?.tenant?.selectedTenant?.languages || [];
  return _languages
    ?.map((language) => ({ id: language, name: getLanguageString(language), image: getLocaleImage(language) }))
    .filter((lang) => hideLanguage(lang.id, { showToSuperAdmins: false, showToDevelopers: false }) !== true);
}

export function getDenominationOptions() {
  const _denominations = ["Mr", "Ms", "Mrs", "Miss", "Other"];
  return _denominations?.map((denomination, index) => {
    return { order: index, id: denomination, localeName: toLiteral({ id: `candidate.denomination.${denomination}`, returnId: false }), name: denomination };
  });
}

export enum CurrencyOptions {
  "Dollars" = "Dollars",
  "Euros" = "Euros",
  "Pounds" = "Pounds",
  "Other" = "Other",
}

export function getCurrencyOptions() {
  const currencies = [CurrencyOptions.Dollars, CurrencyOptions.Euros, CurrencyOptions.Pounds, CurrencyOptions.Other];
  return currencies?.map((denomination, index) => {
    return { order: index, id: denomination, localeName: toLiteral({ id: `administrator.services.process.offerManagement.currency.${denomination}`, returnId: false }), name: denomination };
  });
}

export function getPhaseNameByType(type: number) {
  const phaseLiteral = PhaseTypeName[type] || "unnamed phase";
  return toLiteral({ id: phaseLiteral });
}

export function extractSelectedLanguages(_languages: any, defaultLanguage: string, processLanguages: any[]) {
  // Filter from language array based on existing isoCodes from the multilanguage object
  const filteredLanguages = processLanguages
    .filter((language) => _languages?.includes(language.isoCode))
    .map((language: any) => ({
      ...language,
      default: language.isoCode === defaultLanguage,
    }));

  // Sort the filteredLanguages array so that the default language comes first
  filteredLanguages.sort((a, b) => {
    if (a.isoCode === defaultLanguage) return -1;
    if (b.isoCode === defaultLanguage) return 1;
    return 0;
  });

  return filteredLanguages;
}

export function getTranslation(object: any, key: string, language?: string, subProperty?: string, index?: number) {
  if (object?.multilanguage && language) {
    const languageKey = `${key}__${language}`;
    if (subProperty) {
      if (index !== undefined) {
        return object?.multilanguage[subProperty]?.[index]?.[languageKey] ?? object?.[subProperty]?.[index]?.[key] ?? "";
      } else {
        // there are phases like video where property is inside subProperty but not indexed array like answers.
        return object?.multilanguage[languageKey] ?? object?.[subProperty]?.[key] ?? "";
      }
    } else {
      return object?.multilanguage[languageKey] ?? object?.[key] ?? "";
    }
  } else {
    if (subProperty) {
      // first case is for answers case but in video for example properties are set inside subProperty but not indexed array
      return index !== undefined ? object?.[subProperty]?.[index]?.[key] : object?.[subProperty]?.[key];
    } else {
      return object?.[key] ?? "";
    }
  }
}
