import React, { ReactElement, useEffect } from "react";
import Routes from "../router/routes";
import { getFeatureConfiguration } from "../../../helper/feature-configuration-helper";
import Footer from "../footer/footer";
import store from "../../../redux/store";
import { insertOneTrustBanner, ONE_TRUST_CUSTOM_COOKIE_NAME } from "../cookiesAndTracking/oneTrust";
import { NavItem } from "../../../utilities/navigation";

/**
 * Component that renders the public page
 */
const PublicContainer: React.FC<any> = (props): ReactElement => {
  const currentRoute = store?.getState()?.navigation?.currentRoute;

  useEffect(() => {
    checkAndAddOneTrustBanner(currentRoute);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRoute]);

  function checkAndAddOneTrustBanner(_currentRoute: NavItem) {
    const oneTrustBanner = getFeatureConfiguration("Scripts and Banners Settings", "one-trust-banner");
    const bannerDataDomain = oneTrustBanner?.config?.["banner-data-domain"];
    const bannerSource = oneTrustBanner?.config?.["banner-source"];
    // commenting this to allways inject const hasOptanonCustomConsentProviderCookie = document.cookie.includes(ONE_TRUST_CUSTOM_COOKIE_NAME);
    // Check if OneTrust feature is enabled for Quick Apply Page
    if (_currentRoute?.id === "QUICK_APPLY_WEB_PUBLIC" && oneTrustBanner?.isEnabled) {
      insertOneTrustBanner(bannerSource, bannerDataDomain);
    }
  }

  return (
    <div id="public-container">
      <Routes onShowSnackbar={props.onShowSnackbar} getAppInsights={props.getAppInsights} />
      <Footer />
    </div>
  );
};

export default PublicContainer;
